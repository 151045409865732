import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/homeindex.css'
import Lottie from 'react-lottie'
import * as animationData from '../../assets/animation/RightArrowAnimation.json'
import * as DigitalSalesHomeData from '../../assets/animation/DigitalSalesHomeAnimation'
import * as EazeDropHomeData from '../../assets/animation/EazeeDropHomeAnimation'

const Card = ({ image, title, text, url }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <a href={url} className="card">
      <div className="">
        <Lottie
          options={image}
          height={image.height}
          width={image.width}
          style={{ boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.5);' }}
        />
      </div>
      <h2 className="card__title">{title}</h2>
      <div className="card__content">
        <p>{text}</p>
        <Lottie options={defaultOptions} height={50} width={150} />
      </div>
    </a>
  )
}

const HomeIndex = () => {
  const cards = [
    {
      image: {
        loop: true,
        autoplay: true,
        animationData: DigitalSalesHomeData,
        height: 200,
        width: 200,
        class: 'cell medium-4 animate__animated animate__backInLeft',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'Digital Sales',
      text: 'Enabling user to view and manage retailers ',
      url: '/#/Retailers',
    },
    {
      image: {
        loop: true,
        autoplay: true,
        animationData: EazeDropHomeData,
        height: 200,
        width: 200,
        class: 'cell medium-4 animate__animated animate__backInRight',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      },
      title: 'EazeeDrop Home',
      text: 'Enabling user to assign products to vehical',
      url: '/#/Shipments',
    },
  ]

  return (
    <>
      <div className="grid-container my-5" style={{ margingTop: '2rem' }}>
        <h1 className="text-center my-5">Choose your workspace... </h1>
        <div className="grid-x grid-padding-x grid-margin-y align-center ">
          {cards.map((card, index) => (
            <div className="cell medium-4 animate__animated animate__backInRight" key={index}>
              <Card image={card.image} title={card.title} text={card.text} url={card.url} />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

Card.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.any,
}

export default HomeIndex
