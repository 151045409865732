import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import '../../../src/assets/RetailerInvoice.css'
const initialProductLine = {
  description: '',
  quantity: '1',
  rate: '0.00',
}

const initialInvoice = {
  title: 'INVOICE',
  companyName: '',
  name: '',
  companyAddress: '',
  companyAddress2: '',
  companyCountry: 'United States',
  billTo: 'Bill To:',
  clientName: '',
  clientAddress: '',
  clientAddress2: '',
  clientCountry: 'United States',
  invoiceTitleLabel: 'Invoice#',
  invoiceTitle: '',
  invoiceDateLabel: 'Invoice Date',
  invoiceDate: '',
  invoiceDueDateLabel: 'Due Date',
  invoiceDueDate: '',
  productLineDescription: 'Item Description',
  productLineQuantity: 'Qty',
  productLineQuantityRate: 'Rate',
  productLineQuantityAmount: 'Amount',
  productLines: [
    {
      description: 'Brochure Design',
      quantity: '2',
      rate: '100.00',
    },
    { ...initialProductLine },
    { ...initialProductLine },
  ],
  subTotalLabel: 'Sub Total',
  taxLabel: 'Sale Tax (10%)',
  totalLabel: 'TOTAL',
  currency: '$',
  notesLabel: 'Notes',
  notes: 'It was great doing business with you.',
  termLabel: 'Terms & Conditions',
  term: 'Please make the payment by the due date.',
}

const countryList = [
  // ... (unchanged)
]

const EditableInput = ({ className, placeholder, value, onChange }) => (
  <input
    type="text"
    className={'input ' + (className ? className : '')}
    placeholder={placeholder || ''}
    value={value || ''}
    onChange={onChange ? (e) => onChange(e.target.value) : undefined}
  />
)

const EditableTextarea = ({ className, placeholder, value, onChange, rows }) => (
  <textarea
    // minRows={rows || 1}
    className={'input ' + (className ? className : '')}
    placeholder={placeholder || ''}
    value={value || ''}
    onChange={onChange ? (e) => onChange(e.target.value) : undefined}
  />
)

EditableTextarea.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.any, // Assuming rows is a number
}
const EditableSelect = ({ className, options, placeholder, value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <React.Fragment>
      {isEditing ? (
        <select
          className={'select ' + (className ? className : '')}
          value={value}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
          onBlur={() => setIsEditing(false)}
          autoFocus={true}
        >
          {options?.map((option) => (
            <option key={option.text} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      ) : (
        <input
          readOnly={true}
          type="text"
          className={'input ' + (className ? className : '')}
          value={value || ''}
          placeholder={placeholder || ''}
          onFocus={() => setIsEditing(true)}
        />
      )}
    </React.Fragment>
  )
}
EditableSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ),
  placeholder: PropTypes.string,
  value: PropTypes.any, // Adjust the type according to your data type
  onChange: PropTypes.func,
}
const RetailerInvoice = () => {
  const [invoice, setInvoice] = useState({ ...initialInvoice })
  const [subTotal, setSubTotal] = useState()
  const [saleTax, setSaleTax] = useState()

  const handleChange = (name, value) => {
    if (name !== 'productLines') {
      const newInvoice = { ...invoice }
      newInvoice[name] = value

      setInvoice(newInvoice)
    }
  }

  const handleProductLineChange = (index, name, value) => {
    const productLines = invoice.productLines.map((productLine, i) => {
      if (i === index) {
        const newProductLine = { ...productLine }

        if (name === 'description') {
          newProductLine[name] = value
        } else {
          if (
            value[value.length - 1] === '.' ||
            (value[value.length - 1] === '0' && value.includes('.'))
          ) {
            newProductLine[name] = value
          } else {
            const n = parseFloat(value)

            newProductLine[name] = (n ? n : 0).toString()
          }
        }

        return newProductLine
      }

      return { ...productLine }
    })

    setInvoice({ ...invoice, productLines })
  }

  const handleRemove = (i) => {
    const productLines = invoice.productLines.filter((productLine, index) => index !== i)

    setInvoice({ ...invoice, productLines })
  }

  const handleAdd = () => {
    const productLines = [...invoice.productLines, { ...initialProductLine }]

    setInvoice({ ...invoice, productLines })
  }

  const calculateAmount = (quantity, rate) => {
    const quantityNumber = parseFloat(quantity)
    const rateNumber = parseFloat(rate)

    if (isNaN(quantityNumber) || isNaN(rateNumber)) {
      return 0
    }

    return quantityNumber * rateNumber
  }

  useEffect(() => {
    let subTotalAmount = 0

    invoice.productLines.forEach((productLine) => {
      const amount = calculateAmount(productLine.quantity, productLine.rate)
      subTotalAmount += parseFloat(amount)
    })

    setSubTotal(subTotalAmount)

    const taxRate = 0.1 // 10%
    const taxAmount = subTotalAmount * taxRate
    setSaleTax(taxAmount)
  }, [invoice.productLines])

  return (
    <div className="invoice-wrapper">
      <div className="flex">
        <div className="w-50">
          <EditableInput
            className="fs-20 bold"
            placeholder="Your Company"
            value={invoice.companyName}
            onChange={(value) => handleChange('companyName', value)}
          />
          <EditableInput
            placeholder="Your Name"
            value={invoice.name}
            onChange={(value) => handleChange('name', value)}
          />
          <EditableInput
            placeholder="Company's Address"
            value={invoice.companyAddress}
            onChange={(value) => handleChange('companyAddress', value)}
          />
          <EditableInput
            placeholder="City, State Zip"
            value={invoice.companyAddress2}
            onChange={(value) => handleChange('companyAddress2', value)}
          />
          <EditableSelect
            options={countryList}
            value={invoice.companyCountry}
            onChange={(value) => handleChange('companyCountry', value)}
          />
        </div>
        <div className="w-50">
          <EditableInput
            className="fs-45 right bold"
            placeholder="Invoice"
            value={invoice.title}
            onChange={(value) => handleChange('title', value)}
          />
        </div>
      </div>

      <div className="flex mt-40">
        <div className="w-55">
          <EditableInput
            className="bold dark mb-1"
            value={invoice.billTo}
            onChange={(value) => handleChange('billTo', value)}
          />
          <EditableInput
            placeholder="Your Client's Name"
            value={invoice.clientName}
            onChange={(value) => handleChange('clientName', value)}
          />
          <EditableInput
            placeholder="Client's Address"
            value={invoice.clientAddress}
            onChange={(value) => handleChange('clientAddress', value)}
          />
          <EditableInput
            placeholder="City, State Zip"
            value={invoice.clientAddress2}
            onChange={(value) => handleChange('clientAddress2', value)}
          />
          <EditableSelect
            options={countryList}
            value={invoice.clientCountry}
            onChange={(value) => handleChange('clientCountry', value)}
          />
        </div>
        <div className="w-45">
          <div className="flex mb-1">
            <div className="w-40">
              <EditableInput
                className="bold"
                value={invoice.invoiceTitleLabel}
                onChange={(value) => handleChange('invoiceTitleLabel', value)}
              />
            </div>
            <div className="w-60">
              <EditableInput
                placeholder="INV-12"
                value={invoice.invoiceTitle}
                onChange={(value) => handleChange('invoiceTitle', value)}
              />
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-40">
              <EditableInput
                className="bold"
                value={invoice.invoiceDateLabel}
                onChange={(value) => handleChange('invoiceDateLabel', value)}
              />
            </div>
            <div className="w-60">
              <EditableInput
                placeholder="Jun 30, 2020"
                value={invoice.invoiceDate}
                onChange={(value) => handleChange('invoiceDate', value)}
              />
            </div>
          </div>
          <div className="flex mb-1">
            <div className="w-40">
              <EditableInput
                className="bold"
                value={invoice.invoiceDueDateLabel}
                onChange={(value) => handleChange('invoiceDueDateLabel', value)}
              />
            </div>
            <div className="w-60">
              <EditableInput
                placeholder="Jul 30, 2020"
                value={invoice.invoiceDueDate}
                onChange={(value) => handleChange('invoiceDueDate', value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-30 bg-dark flex">
        <div className="w-48 p-4-8">
          <EditableInput
            className="white bold"
            value={invoice.productLineDescription}
            onChange={(value) => handleChange('productLineDescription', value)}
          />
        </div>
        <div className="w-17 p-4-8">
          <EditableInput
            className="white bold right"
            value={invoice.productLineQuantity}
            onChange={(value) => handleChange('productLineQuantity', value)}
          />
        </div>
        <div className="w-17 p-4-8">
          <EditableInput
            className="white bold right"
            value={invoice.productLineQuantityRate}
            onChange={(value) => handleChange('productLineQuantityRate', value)}
          />
        </div>
        <div className="w-18 p-4-8">
          <EditableInput
            className="white bold right"
            value={invoice.productLineQuantityAmount}
            onChange={(value) => handleChange('productLineQuantityAmount', value)}
          />
        </div>
      </div>

      {invoice.productLines.map((productLine, i) => {
        return (
          <div key={i} className="flex">
            <div className="w-48 p-4-8 ">
              <EditableTextarea
                className="dark"
                rows={2}
                placeholder="Enter item name/description"
                value={productLine.description}
                onChange={(value) => handleProductLineChange(i, 'description', value)}
              />
            </div>
            <div className="w-17 p-4-8 pb-10">
              <EditableInput
                className="dark right"
                value={productLine.quantity}
                onChange={(value) => handleProductLineChange(i, 'quantity', value)}
              />
            </div>
            <div className="w-17 p-4-8 pb-10">
              <EditableInput
                className="dark right"
                value={productLine.rate}
                onChange={(value) => handleProductLineChange(i, 'rate', value)}
              />
            </div>
            <div className="w-18 p-4-8 pb-10">
              <span className="span dark right">
                {calculateAmount(productLine.quantity, productLine.rate)}
              </span>
            </div>
            <button
              className="link row__remove"
              aria-label="Remove Row"
              title="Remove Row"
              onClick={() => handleRemove(i)}
            >
              <span className="icon icon-remove bg-red"></span>
            </button>
          </div>
        )
      })}

      <div className="flex">
        <div className="w-50 mt-10">
          <button className="link" onClick={handleAdd}>
            <span className="icon icon-add bg-green mr-10"></span>
            Add Line Item
          </button>
        </div>
        <div className="w-50 mt-1">
          <div className="flex m-1">
            <div className="w-50 px-5">
              <EditableInput
                value={invoice.subTotalLabel}
                onChange={(value) => handleChange('subTotalLabel', value)}
              />
            </div>
            <div className="w-50 px-5">
              <span className="span right bold dark">{subTotal}</span>
            </div>
          </div>
          <div className="flex">
            <div className="w-50 px-5">
              <EditableInput
                value={invoice.taxLabel}
                onChange={(value) => handleChange('taxLabel', value)}
              />
            </div>
            <div className="w-50 px-5">
              <span className="span right bold dark">{saleTax}</span>
            </div>
          </div>
          <div className="flex bg-gray ">
            <div className="w-50 px-5">
              <EditableInput
                className="bold"
                value={invoice.totalLabel}
                onChange={(value) => handleChange('totalLabel', value)}
              />
            </div>
            <div className="w-50 px-5 flex">
              <EditableInput
                className="dark bold right ml-30"
                value={invoice.currency}
                onChange={(value) => handleChange('currency', value)}
              />
              <span className="span right bold dark w-auto">
                {typeof subTotal !== 'undefined' && typeof saleTax !== 'undefined'
                  ? subTotal + saleTax
                  : 0}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <EditableInput
          className="bold w-100"
          value={invoice.notesLabel}
          onChange={(value) => handleChange('notesLabel', value)}
        />
        <EditableTextarea
          className="w-100"
          rows={2}
          value={invoice.notes}
          onChange={(value) => handleChange('notes', value)}
        />
      </div>
      <div className="mt-20">
        <EditableInput
          className="bold w-100"
          value={invoice.termLabel}
          onChange={(value) => handleChange('termLabel', value)}
        />
        <EditableTextarea
          className="w-100"
          rows={2}
          value={invoice.term}
          onChange={(value) => handleChange('term', value)}
        />
      </div>
    </div>
  )
}

EditableInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
export default RetailerInvoice
