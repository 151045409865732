import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ShareableTracking from './views/dashboard/ShareableTracking'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const ShareableTrackingRouter = () => (
  <Router>
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/ShareableTracking/:uuid/" element={<ShareableTracking />} />
      </Routes>
    </Suspense>
  </Router>
)

export default ShareableTrackingRouter
